import React from "react";
import { useSelector } from "react-redux";
import { selectAuthWizardStep } from "../../selectors/appSelectors";
import { AUTH_STEPS } from "./constants";

const AuthenticationWizard = () => {
  const authWizardStep = useSelector(selectAuthWizardStep);

  return <>{React.createElement(AUTH_STEPS[authWizardStep])}</>;
};

export default AuthenticationWizard;
