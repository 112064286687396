import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBrand,
  selectFormFieldError,
  selectFormFieldIsRequired,
  selectProfile,
  selectProfileFormField,
  selectView,
} from "../../selectors/appSelectors";
import { setProfileFormField } from "../../actions/appActions";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "../../api/axios";
import { getAuthorization } from "../../utils/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

const ProfileFormFieldCustom = (props) => {
  const { addedField } = props;
  const name = addedField.name;

  const dispatch = useDispatch();

  const value = useSelector((state) => selectProfileFormField(state, { name }));
  const error = useSelector((state) => selectFormFieldError(state, { name }));

  const handleChange = (event) => {
    dispatch(setProfileFormField({ name, value: event.target.value }));
  };

  if (addedField.type === "text") {
    return (
      <TextField
        key={addedField.name}
        fullWidth
        margin="normal"
        id={addedField.name}
        label={`${addedField.label}${addedField.required ? " *" : ""}`}
        name={addedField.name}
        value={value}
        onChange={handleChange}
        error={!!error}
        helperText={!!error ? error : addedField.helperText ?? ""}
      />
    );
  }

  if (addedField.type === "singleChoice") {
    return (
      <FormControl
        key={addedField.name}
        fullWidth
        margin="normal"
        error={!!error}
        helperText={!!error ? error : ""}
      >
        <InputLabel id={addedField.name}>{`${addedField.label}${
          addedField.required ? " *" : ""
        }`}</InputLabel>
        <Select
          labelId={addedField.name}
          id={addedField.name}
          name={addedField.name}
          value={value}
          label={`${addedField.label}${addedField.required ? " *" : ""}`}
          onChange={handleChange}
        >
          {addedField.options.map((o) => {
            return <MenuItem value={o.value}>{o.label}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  }

  return <></>;
};

const ProfileFormField = (props) => {
  const { label, name } = props;

  const dispatch = useDispatch();

  const value = useSelector((state) => selectProfileFormField(state, { name }));
  const error = useSelector((state) => selectFormFieldError(state, { name }));
  const required = useSelector((state) =>
    selectFormFieldIsRequired(state, { name })
  );

  const handleChange = (event) => {
    dispatch(setProfileFormField({ name, value: event.target.value }));
  };

  return (
    <>
      <TextField
        fullWidth
        margin="normal"
        id={name}
        label={required ? <>{label} *</> : <>{label}</>}
        name={name}
        value={value}
        onChange={handleChange}
        error={!!error}
        helperText={!!error ? error : ""}
      />
    </>
  );
};

const ProfileFormFieldSex = () => {
  const name = "sex";

  const dispatch = useDispatch();

  const value = useSelector((state) => {
    return selectProfileFormField(state, { name });
  });

  const handleChange = (event) => {
    dispatch(setProfileFormField({ name, value: event.target.value }));
  };

  const options = [
    { key: 0, label: "Neuvedeno" },
    { key: 1, label: "Muž" },
    { key: 2, label: "Žena" },
    { key: 3, label: "Jiné" },
  ];

  return (
    <>
      <FormControl fullWidth margin="normal">
        <FormLabel htmlFor="sex">Pohlaví</FormLabel>
        <Select labelId="sex" value={value} onChange={handleChange}>
          {options.map((option) => {
            return (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const ProfileFormFieldBirthday = () => {
  const name = "birthday";

  const dispatch = useDispatch();
  const view = useSelector((state) => selectView(state));

  const value = useSelector((state) => {
    return selectProfileFormField(state, { name });
  });

  const handleChange = (newValue) => {
    if (!!newValue) {
      newValue.setHours(5, 0, 0);
    }
    dispatch(setProfileFormField({ name, value: newValue }));
  };

  return (
    <DatePicker
      disabled={view === "/profil"}
      label="Datum narození"
      value={value}
      onChange={handleChange}
      openTo={"year"}
      views={["year", "month", "day"]}
      renderInput={(params) => (
        <TextField
          margin="normal"
          fullWidth
          helperText={"Můžete získávat narozeninové slevy"}
          {...params}
        />
      )}
    />
  );
};

const ProfileFormEmail = () => {
  const name = "login";
  const label = "E-mailová adresa";
  const required = true;

  const dispatch = useDispatch();
  const profile = useSelector((state) => selectProfile(state));

  const [newValue, setNewValue] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setError("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (newValue.length > 2 && !emailRegex.test(newValue)) {
      setError("Zadaná e-mailová adresa nemá správný formát.");
    }
  }, [newValue]);

  if (!profile) {
    return <></>;
  }

  const value = profile.login;

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/change-email/request",
        { newValue },
        {
          headers: {
            Authorization: getAuthorization(),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "User already exists.") {
            setError(
              "Tato e-mailová adresa v systému již existuje. Změnu na tuto adresu není možné provést."
            );
          } else {
            setSubmitted(true);
          }
        }
      })
      .finally((error) => {
        setLoading(false);
        // logout(dispatch, navigate);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            margin="normal"
            id={name}
            label={required ? <>{label} *</> : <>{label}</>}
            name={name}
            value={value}
            disabled={true}
          />
        </Grid>
        <Grid item xs={4} sx={{ pb: 1, mt: 2, boxSizing: "border-box" }}>
          <Button
            fullWidth
            sx={{
              height: "100%",
            }}
            size={"small"}
            onClick={() => {
              setOpen(true);
            }}
            variant={"outlined"}
            startIcon={<EditIcon />}
          >
            Změnit e-mail
          </Button>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Změna e-mailové adresy</DialogTitle>
        <Divider />
        <DialogContent>
          {submitted && (
            <Alert severity={"success"}>
              Na zadanou e-mailovou adresu jsme odeslali Potvrzovací e-mail.
              Změna bude dokončena kliknutím na odkaz v daném e-mailu.
            </Alert>
          )}
          {!submitted && (
            <>
              <Typography>
                Zadejte svoji novou e-mailovou adresu. Po kliknutí na tlačítko
                Uložit e-mail, Vám na ni pošleme odkaz pro potvrzení dokončení
                změny. Do té doby nebude adresa změněna.
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                label={
                  required ? (
                    <>{"Nová e-mailová adresa"} *</>
                  ) : (
                    <>{"Nová e-mailová adresa"}</>
                  )
                }
                value={newValue}
                onChange={(e) => {
                  setNewValue(e.target.value);
                }}
                error={error !== ""}
                helperText={error}
              />
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"outlined"}
            onClick={handleClose}
            startIcon={<CloseIcon />}
          >
            Zavřít
          </Button>
          {!submitted && (
            <LoadingButton
              variant={"contained"}
              loading={loading}
              disabled={error !== ""}
              onClick={handleSubmit}
              startIcon={<CheckIcon />}
            >
              Uložit e-mail
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const ProfileForm = () => {
  const brand = useSelector(selectBrand);
  const addedFields = Object.values(brand?.profileForm?.addedFields || {});

  return (
    <>
      <ProfileFormEmail />

      <ProfileFormField name={"firstName"} label={"Jméno"} />

      <ProfileFormField name={"lastName"} label={"Příjmení"} />

      {addedFields.map((addedField) => {
        return (
          <ProfileFormFieldCustom
            key={addedField.name}
            addedField={addedField}
          />
        );
      })}

      <ProfileFormFieldSex />

      <ProfileFormFieldBirthday />

      <ProfileFormField name={"addressStreet"} label={"Ulice"} />

      <ProfileFormField name={"addressStreetNumber"} label={"Číslo popisné"} />

      <ProfileFormField name={"addressCity"} label={"Město"} />

      <ProfileFormField name={"addressPostCode"} label={"PSČ"} />
    </>
  );
};

export default ProfileForm;
