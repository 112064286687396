export const BT = {
  signinForm_subtitle: 'signinForm_subtitle'
}

const brandTexts = {
  relay: {
    'signinForm_subtitle': 'Registrace nového zákazníka'
  },
  airclub: {
    'signinForm_subtitle': 'Registrace nového zaměstnance'
  }
}

export const getBrandText = (brandName, code) => {

  if(brandTexts.hasOwnProperty(brandName) && brandTexts[brandName].hasOwnProperty(code)){
    return brandTexts[brandName][code];
  }

  return null;
}