import { configureStore } from "@reduxjs/toolkit";
import { appReducer } from "../reducers/appReducer";
import thunk from "redux-thunk";
import devReducer from "../dev/devReducer";

const middleware = [thunk];

const store = configureStore({
  reducer: {
    app: appReducer,
    dev: devReducer,
  },
  middleware: middleware,
});

export default store;
