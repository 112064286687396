import React from "react";
import axios from "../../../api/axios";
import { getAuthorizationByName } from "../../../utils/auth";
import { selectProfile } from "../../../selectors/appSelectors";
import { LOCAL_STORAGE_AUTH_TOKEN_WEBSITE } from "../../../utils/localStorage";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { callApiForProfilePage } from "../utils";
import MediaDownloadToGooglePayButton from "../../MediaDownloadButton/MediaDownloadToGooglePayButton";
import MediaDownloadToAppleWalletButton from "../../MediaDownloadButton/MediaDownloadToAppleWalletButton";
import ProfileLoading from "../../ProfileLoading/ProfileLoading";
import { formatDate } from "../../../utils/datetime";

const MyCards = () => {
  const profile = useSelector(selectProfile);

  const [loading, setLoading] = React.useState(false);
  const [encryptedUserNumber, setEncryptedUserNumber] = React.useState("");
  const [cards, setCards] = React.useState([]);

  const load = (profile) => {
    axios
      .request({
        method: "GET",
        url:
          process.env.REACT_APP_SERVER_URL +
          "/crypto/encrypt?text=" +
          profile.userNumber,
        data: {},
        headers: {
          Authorization: getAuthorizationByName(
            LOCAL_STORAGE_AUTH_TOKEN_WEBSITE
          ),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setEncryptedUserNumber(response.data.encrypted);
        }
      });

    callApiForProfilePage({
      url: "/airme-proxy/PAUsers/getTags/" + profile.userId,
      setLoading,
      onSuccess: (r) => {
        if (r.status === 200) {
          setCards(r.data[0]);
        }
      },
    });
  };

  React.useEffect(() => {
    if (!!profile) {
      load(profile);
    }
  }, [profile]);

  if (loading) {
    return <ProfileLoading />;
  }

  return (
    <>
      <Grid container spacing={4}>
        {cards
          .filter((card) => {
            return card.status === 1;
          })
          .map((card) => {
            return (
              <Grid item sm={6}>
                <Card key={card.tagId} variant={"outlined"}>
                  <CardContent sx={{ bgcolor: "#fff" }}>
                    <Typography
                      sx={{
                        p: 2,
                        textAlign: "center",
                        fontSize: 30,
                        fontWeight: 900,
                      }}
                    >
                      {card.number}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ alignItems: "flex-start" }}>
                    <MediaDownloadToAppleWalletButton
                      profile={profile}
                      type={"CARD"}
                      card={card}
                    />
                    <MediaDownloadToGooglePayButton
                      profile={profile}
                      type={"CARD"}
                      card={card}
                    />
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default MyCards;
