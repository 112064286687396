import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectProfile } from "../../../selectors/appSelectors";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { formatDate } from "../../../utils/datetime";
import axios from "../../../api/axios";
import { getAuthorization } from "../../../utils/auth";
import { setProfile } from "../../../actions/appActions";

const InfoDeleteAccount = (props) => {
  const { upsert } = props;

  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  const [loading, setLoading] = React.useState(false);

  const handleCancelDelete = () => {
    setLoading(true);

    const data = {
      login: profile.login,
      userNumber: profile.userNumber,
      contacts: profile.contacts,
      options: profile.options,
      attributes: profile.attributes,
    };

    const attribute = {
      meaning: 0,
      name: "requestForciblyForgotten",
      value: "False",
    };

    const attributes = profile.hasOwnProperty("attributes")
      ? [...profile.attributes]
      : [];
    const index = attributes.findIndex((a) => {
      return a.name === attribute.name;
    });
    if (index > -1) {
      attributes[index] = attribute;
    } else {
      attributes.push(attribute);
    }

    data.attributes = attributes;

    upsert(
      data,
      () => {
        axios
          .get(process.env.REACT_APP_SERVER_URL + "/user/me", {
            headers: {
              Authorization: getAuthorization(),
            },
          })
          .then((response) => {
            dispatch(
              setProfile({
                profile: response.data,
              })
            );
          })
          .catch((error) => {
            // logout(dispatch, navigate);
          });
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const getAttribute = (profile) => {
    if (profile.hasOwnProperty("attributes")) {
      const index = profile.attributes.findIndex((a) => {
        return a.name === "requestForciblyForgotten" && a.value === "True";
      });
      if (index > -1) {
        return profile.attributes[index];
      }
    }
    return null;
  };

  const getScheduledAction = (profile) => {
    if (profile.hasOwnProperty("scheduledActions")) {
      const index = profile.scheduledActions.findIndex((a) => {
        return a.type === "DELETE_ACCOUNT";
      });
      if (index > -1) {
        return profile.scheduledActions[index];
      }
    }
    return null;
  };

  if (!(!getAttribute(profile) && getScheduledAction(profile))) {
    return <></>;
  }

  return (
    <Box mb={6}>
      <Alert severity={"error"}>
        <AlertTitle>
          <strong>Váš účet se odstraňuje</strong>
        </AlertTitle>
        Rozhodli jste se odejít a Váš účet se odstraňuje – pokud jste změnili
        názor, ještě můžete! Účet bude definitivně odstraněn k{" "}
        <strong>{formatDate(getScheduledAction(profile).executedAt)}</strong>,
        do té doby jej můžete jednoduše obnovit tlačítkem „Zrušit odstranění“
        níže. Definitivním odstraněním přijdete o všechny Vaše výhody, získané
        body a nebude již možné čerpat žádné slevy.
        <Box mt={2}>
          <Button
            color={"primary"}
            variant={"contained"}
            size="medium"
            disabled={loading}
            onClick={handleCancelDelete}
          >
            Zrušit odstranění
          </Button>
        </Box>
      </Alert>
    </Box>
  );
};

export default InfoDeleteAccount;
