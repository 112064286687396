import React from "react";
import "./App.css";
import {
  CircularProgress,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Layout from "./layouts/Layout";
import { views } from "./constants/views";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBrand,
  selectStandalone,
  selectView,
} from "./selectors/appSelectors";
import {
  addNotification,
  setAuthWizardMethod,
  setAuthWizardStep,
  setProfile,
  setWebsite,
} from "./actions/appActions";
import DebugBar from "./dev/components/DebugBar/DebugBar";
import {
  hasLocalStorage,
  LOCAL_STORAGE_AUTH_TOKEN_USER,
  LOCAL_STORAGE_AUTH_TOKEN_WEBSITE,
  removeLocalStorage,
  setLocalStorage,
} from "./utils/localStorage";
import axios from "./api/axios";
import useNavigate from "./hooks/useNavigate";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import cs from "date-fns/locale/cs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  AUTH_BY_EMAIL_STEP_CHANGE_EMAIL_EXECUTION_FORM,
  AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM,
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM,
  AUTH_BY_EMAIL_STEP_LOGIN_FORM,
  AUTH_BY_EMAIL_STEP_SIGNIN_FORM,
  AUTH_BY_POS_START,
} from "./components/AuthenticationWizard/constants";
import { handleChangeAuthWizardForm } from "./components/AuthenticationWizard/utils";
import { Box } from "@mui/material";
import Notifier from "./components/Notifier/Notifier";
import { SnackbarProvider } from "notistack";
import { getAuthorization, logout } from "./utils/auth";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const view = useSelector(selectView);
  const brand = useSelector(selectBrand);
  const standalone = useSelector(selectStandalone);

  const [loading, setLoading] = React.useState(false);

  const initialLoad = async () => {
    setLoading(true);

    const website = window.location.origin + window.location.pathname;

    axios
      .post(process.env.REACT_APP_SERVER_URL + "/website/hello", {
        website,
      })
      .then((response) => {
        if (response.status === 200) {
          setLocalStorage(
            LOCAL_STORAGE_AUTH_TOKEN_WEBSITE,
            response.data.token
          );
          dispatch(setWebsite(response.data));

          const urlParams = {};
          window.location.search
            .replace("?", "")
            .split("&")
            .forEach((s) => {
              const splitted = s.split("=");
              urlParams[splitted[0]] = splitted[1];
            });

          //if browser has token user in local storage
          if (
            hasLocalStorage(LOCAL_STORAGE_AUTH_TOKEN_USER) &&
            !urlParams.t &&
            !urlParams.v
          ) {
            navigate("/profil");
          }

          if (urlParams.hasOwnProperty("v") && urlParams.v === "registrace") {
            dispatch(setAuthWizardMethod("EMAIL"));
            dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_SIGNIN_FORM));
          }

          if (urlParams.hasOwnProperty("v") && urlParams.v === "prihlaseni") {
            dispatch(setAuthWizardMethod("EMAIL"));
            dispatch(setAuthWizardStep(AUTH_BY_EMAIL_STEP_LOGIN_FORM));
          }

          if (urlParams.hasOwnProperty("v") && urlParams.v === "pos") {
            dispatch(setAuthWizardMethod("POS"));
            dispatch(setAuthWizardStep(AUTH_BY_POS_START));
            navigate("/pos");
          }

          if (
            urlParams.hasOwnProperty("v") &&
            urlParams.hasOwnProperty("t") &&
            urlParams.v === "obnova-hesla"
          ) {
            handleChangeAuthWizardForm({
              target: { name: "forgottenPasswordToken", value: urlParams.t },
            });
            dispatch(
              setAuthWizardStep(
                AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM
              )
            );
          }

          if (
            urlParams.hasOwnProperty("v") &&
            urlParams.hasOwnProperty("t") &&
            urlParams.v === "zmena-emailu"
          ) {
            removeLocalStorage(LOCAL_STORAGE_AUTH_TOKEN_USER);
            handleChangeAuthWizardForm({
              target: { name: "changeEmailToken", value: urlParams.t },
            });
            dispatch(
              setAuthWizardStep(AUTH_BY_EMAIL_STEP_CHANGE_EMAIL_EXECUTION_FORM)
            );
          }

          if (
            urlParams.hasOwnProperty("v") &&
            urlParams.hasOwnProperty("t") &&
            urlParams.v === "dokonceni-registrace"
          ) {
            axios
              .get(
                process.env.REACT_APP_SERVER_URL +
                  "/user/complete-registration/" +
                  urlParams.t,
                {
                  headers: {
                    Authorization: getAuthorization(),
                  },
                }
              )
              .then((response) => {
                if (response.data.hasOwnProperty("email")) {
                  handleChangeAuthWizardForm({
                    target: { name: "login", value: response.data.email },
                  });
                  if (response.data.hasOwnProperty("airmeResponse")) {
                    dispatch(
                      setProfile({ profile: response.data.airmeResponse })
                    );
                  }
                  dispatch(
                    setAuthWizardStep(AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM)
                  );
                }
              });
          }
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            message: "This website is not registered",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    initialLoad();
  }, []);

  React.useEffect(() => {
    if (!!brand) {
      // document.title = brand.title;
      document.querySelector("[rel=icon]").href = brand.faviconHref;
    }
  }, [brand]);

  if (loading) {
    return (
      <>
        <Box sx={{ textAlign: "center", mt: 10 }}>
          <CircularProgress color="inherit" />
        </Box>
      </>
    );
  }

  if (!brand) {
    return <>This website is not registered</>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
      <ThemeProvider theme={standalone ? brand.themeStandalone : brand.theme}>
        <SnackbarProvider>
          <Notifier />
          <Layout>
            {standalone ? <CssBaseline /> : <></>}
            {React.createElement(
              views[view].layout,
              null,
              React.createElement(views[view].component)
            )}
          </Layout>
          {process.env.REACT_APP_ENV === "dev" && <DebugBar />}
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
