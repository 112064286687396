import {
  addNotification,
  setAuthWizardCheckedUser,
  setAuthWizardForm,
  setAuthWizardLoading,
  setAuthWizardStep,
  setFormErrors,
} from "../../actions/appActions";
import {
  selectAuthWizardCheckedUser,
  selectAuthWizardForm,
  selectAuthWizardNextStep,
  selectAuthWizardStep,
  selectBrand,
  selectBrandThemeName,
  selectProfileForm,
} from "../../selectors/appSelectors";
import store from "../../store/store";
import axios from "../../api/axios";
import { getAuthorization } from "../../utils/auth";
import {
  AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM,
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM,
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM,
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESETED_FORM,
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_THANK_YOU_FORM,
  AUTH_BY_EMAIL_STEP_LOGIN_FORM,
  AUTH_BY_EMAIL_STEP_SIGNIN_FORM,
  AUTH_BY_EMAIL_STEP_SIGNIN_THANK_YOU_FORM,
  AUTH_BY_POS_CUSTOMER_CREATED,
  AUTH_BY_POS_CUSTOMER_EXISTS_AND_LOYAL,
  AUTH_BY_POS_CUSTOMER_EXISTS_AND_NOT_LOYAL,
  AUTH_BY_POS_CUSTOMER_NOT_EXISTS,
  AUTH_BY_POS_FORGOTTEN_PASSWORD_RESET_REQUESTED,
  AUTH_BY_POS_PHYSICAL_CARD_REQUEST,
  AUTH_BY_POS_PHYSICAL_CARD_REQUESTED,
  AUTH_BY_POS_START,
  AUTH_BY_POS_VIRTUAL_CARD_REQUESTED,
} from "./constants";
import { validationValidateForm } from "../../utils/validation";
import {
  LOCAL_STORAGE_AUTH_TOKEN_USER,
  setLocalStorage,
} from "../../utils/localStorage";
import {
  addOrModifyAttribute,
  addAttributeFullyRegistred,
  buildProfile,
  buildProfileAttributes,
  buildProfileOptions,
  buildProfileRewards,
} from "../../utils/dataTransformers";
import {
  getValidationSchema,
  getValidationSchemaForBrand,
} from "./validationSchemas";

export const handleChangeAuthWizardForm = (event) => {
  const { name, value } = event.target;

  const state = store.getState();
  const dispatch = store.dispatch;

  const form = selectAuthWizardForm(state);

  dispatch(setAuthWizardForm({ ...form, [name]: value }));
};

const axiosPost = ({
  method = "POST",
  url,
  data,
  onThen,
  onCatch = (error) => {},
  onFinally = (response) => {},
  setLoadingToTrue = true,
  setLoadingToFalse = true,
}) => {
  const dispatch = store.dispatch;
  if (setLoadingToTrue) {
    dispatch(setAuthWizardLoading(true));
  }
  axios
    .request({
      method,
      url,
      data,
      headers: {
        Authorization: getAuthorization(),
      },
    })
    .then((response) => {
      onThen(response);
    })
    .catch((error) => {
      onCatch(error);
    })
    .finally((response) => {
      onFinally(response);
      if (setLoadingToFalse) {
        dispatch(setAuthWizardLoading(false));
      }
    });
};

export const handleSubmitAuthWizardForm = (params) => {
  const state = store.getState();
  const dispatch = store.dispatch;

  const step = selectAuthWizardStep(state);
  const nextStep = selectAuthWizardNextStep(state);
  const checkedUser = selectAuthWizardCheckedUser(state);
  const form = selectAuthWizardForm(state);
  const profileForm = selectProfileForm(state);
  const brand = selectBrand(state);
  const brandThemeName = selectBrandThemeName(state);

  dispatch(setFormErrors({}));

  const { isValid: isValid1 = true, errors: errors1 = {} } =
    validationValidateForm(getValidationSchema(step, form), form);

  const { isValid: isValid2 = true, errors: errors2 = {} } =
    validationValidateForm(
      getValidationSchemaForBrand(step, brand),
      profileForm
    );

  if (!isValid1 || !isValid2) {
    dispatch(setFormErrors({ ...errors1, ...errors2 }));
  }

  if (isValid1 && isValid2) {
    switch (step) {
      case AUTH_BY_EMAIL_STEP_LOGIN_FORM:
        {
          axiosPost({
            url: process.env.REACT_APP_SERVER_URL + "/user/login",
            data: {
              login: form.login,
              password: form.password,
            },
            onThen: (response) => {
              setLocalStorage(
                LOCAL_STORAGE_AUTH_TOKEN_USER,
                response.data.token
              );
              params.navigate("/profil");
            },
            onCatch: (error) => {
              dispatch(setFormErrors({ password: "Chybné heslo" }));
            },
          });
        }
        break;
      case AUTH_BY_EMAIL_STEP_SIGNIN_FORM:
        {
          axiosPost({
            url: process.env.REACT_APP_SERVER_URL + "/user/preregister",
            data: {
              login: form.login,
              // companyTitle: form.companyTitle || "",
              // employeeCardNumber: form.employeeCardNumber || "",
            },
            onThen: (response) => {
              dispatch(
                setAuthWizardStep(AUTH_BY_EMAIL_STEP_SIGNIN_THANK_YOU_FORM)
              );
            },
            onCatch: (error) => {
              if (error.response.status === 422) {
                dispatch(
                  setFormErrors({
                    login: "Uživatel s tímto e-mailem už existuje.",
                  })
                );
              } else {
                dispatch(
                  addNotification({
                    message: "Network error",
                    severity: "error",
                  })
                );
              }
            },
          });
        }
        break;
      case AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM:
        {
          const data = buildProfile({
            login: form.login,
            password: form.password,
            profileForm,
            rewards: buildProfileRewards(),
            options: buildProfileOptions({ profileForm }),
            attributes: buildProfileAttributes({
              RegistredAtEshop: true,
            }),
          });
          addAttributeFullyRegistred(data, "True");
          if (!!profileForm.companyTitle && brandThemeName === "airclub") {
            addOrModifyAttribute(
              data,
              "companyTitle",
              profileForm.companyTitle || undefined
            );
            addOrModifyAttribute(data, "companySalePercentage", 0.3); // při registraci pokud je zadáno companyTitle -> dávám slevu 0.3
            addOrModifyAttribute(
              data,
              "employeeCardNumber",
              profileForm.employeeCardNumber || undefined
            );
          }

          axiosPost({
            url: process.env.REACT_APP_SERVER_URL + "/user/register",
            data: data,
            onThen: (response) => {
              setLocalStorage(
                LOCAL_STORAGE_AUTH_TOKEN_USER,
                response.data.token
              );
              params.navigate("/profil");
            },
            onCatch: (error) => {
              dispatch(
                addNotification({
                  message: "Network error",
                  severity: "error",
                })
              );
            },
          });
        }
        break;
      case AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM:
        {
          axiosPost({
            url: process.env.REACT_APP_SERVER_URL + "/reset-password/request",
            data: {
              email: form.login,
            },
            onThen: (response) => {
              dispatch(
                setAuthWizardStep(
                  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_THANK_YOU_FORM
                )
              );
            },
            onCatch: (error) => {
              dispatch(
                addNotification({
                  message: "Network error",
                  severity: "error",
                })
              );
            },
          });
        }
        break;
      case AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM:
        {
          axiosPost({
            url: process.env.REACT_APP_SERVER_URL + "/reset-password/reset",
            data: {
              password: form.password,
              token: form.forgottenPasswordToken,
            },
            onThen: (response) => {
              if (response.status === 200) {
                dispatch(
                  setAuthWizardStep(
                    AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESETED_FORM
                  )
                );
              }
            },
            onCatch: (error) => {
              dispatch(
                addNotification({
                  message: "Network error",
                  severity: "error",
                })
              );
            },
          });
        }
        break;
      case AUTH_BY_POS_START:
        {
          dispatch(
            setAuthWizardCheckedUser({
              login: form.login,
              exists: false,
              loyalty: false,
              userNumber: null,
            })
          );
          axiosPost({
            url: process.env.REACT_APP_SERVER_URL + "/user/check",
            data: {
              login: form.login,
            },
            onThen: (response) => {
              if (response.status === 200) {
                const customer = response.data[0];
                if (
                  customer.rewards.findIndex(
                    (r) => r.name === "Basic" && [30].includes(r.loyaltyLevel)
                  ) !== -1
                ) {
                  //customer exists and is in loyalty club
                  dispatch(
                    setAuthWizardCheckedUser({
                      login: form.login,
                      exists: true,
                      loyalty: true,
                      userNumber: customer.userNumber,
                    })
                  );
                  dispatch(
                    setAuthWizardStep(AUTH_BY_POS_CUSTOMER_EXISTS_AND_LOYAL)
                  );
                }
                if (
                  customer.rewards.findIndex(
                    (r) => r.name === "Basic" && [0, 1].includes(r.loyaltyLevel)
                  ) !== -1
                ) {
                  //customer does not exist and is not in loyalty club
                  dispatch(
                    setAuthWizardCheckedUser({
                      login: form.login,
                      exists: true,
                      loyalty: false,
                      userNumber: customer.userNumber,
                    })
                  );
                  dispatch(
                    setAuthWizardStep(AUTH_BY_POS_CUSTOMER_EXISTS_AND_NOT_LOYAL)
                  );
                }
              } else {
                dispatch(
                  addNotification({
                    message: "Network error: HTTP Response code is not 200",
                    severity: "error",
                  })
                );
              }
            },
            onCatch: (error, response) => {
              if (error.response.status === 404) {
                dispatch(
                  setAuthWizardCheckedUser({
                    login: form.login,
                    exists: false,
                    loyalty: false,
                    userNumber: null,
                  })
                );
                dispatch(setAuthWizardStep(AUTH_BY_POS_CUSTOMER_NOT_EXISTS));
              } else {
                dispatch(
                  addNotification({
                    message:
                      "Network error: HTTP Response code is not 200 or 404",
                    severity: "error",
                  })
                );
              }
            },
          });
        }
        break;
      case AUTH_BY_POS_CUSTOMER_NOT_EXISTS:
        {
          switch (nextStep) {
            case "add_virtual_card":
              {
                const data = buildProfile({
                  login: form.login,
                  options: buildProfileOptions({ profileForm: null }),
                  rewards: buildProfileRewards(),
                  attributes: buildProfileAttributes({
                    RegistredAtStore: true,
                  }),
                });
                addAttributeFullyRegistred(data, "False");

                axiosPost({
                  url: process.env.REACT_APP_SERVER_URL + "/user/upsert",
                  data: data,
                  onThen: (response2) => {
                    dispatch(setAuthWizardStep(AUTH_BY_POS_CUSTOMER_CREATED));
                  },
                  onCatch: (error2) => {
                    dispatch(
                      addNotification({
                        message: "Network error",
                        severity: "error",
                      })
                    );
                  },
                  onFinally: () => {
                    dispatch(setAuthWizardLoading(false));
                  },
                });
              }
              break;
            case "add_physical_card":
              {
                dispatch(setAuthWizardStep(AUTH_BY_POS_PHYSICAL_CARD_REQUEST));
              }
              break;
            case "change_login":
              {
                dispatch(setAuthWizardStep(AUTH_BY_POS_START));
              }
              break;
          }
        }
        break;
      case AUTH_BY_POS_CUSTOMER_EXISTS_AND_LOYAL:
      case AUTH_BY_POS_CUSTOMER_EXISTS_AND_NOT_LOYAL:
        {
          switch (nextStep) {
            case "add_virtual_card":
              {
                //console.log(checkedUser);
                const userNumber = checkedUser.userNumber;
                axiosPost({
                  url:
                    process.env.REACT_APP_SERVER_URL +
                    "/user/addTag/" +
                    userNumber,
                  data: {},
                  onThen: (response2) => {
                    dispatch(
                      setAuthWizardStep(AUTH_BY_POS_VIRTUAL_CARD_REQUESTED)
                    );
                  },
                  onCatch: (error2) => {
                    dispatch(
                      addNotification({
                        message: "Network error",
                        severity: "error",
                      })
                    );
                  },
                  onFinally: () => {
                    dispatch(setAuthWizardLoading(false));
                  },
                });
              }
              break;
            case "change_login":
              {
                dispatch(setAuthWizardStep(AUTH_BY_POS_START));
              }
              break;
            case "reset_password_request":
              {
                axiosPost({
                  url:
                    process.env.REACT_APP_SERVER_URL +
                    "/reset-password/request",
                  data: {
                    email: form.login,
                  },
                  onThen: (response) => {
                    dispatch(
                      setAuthWizardStep(
                        AUTH_BY_POS_FORGOTTEN_PASSWORD_RESET_REQUESTED
                      )
                    );
                  },
                  onCatch: (error) => {
                    dispatch(
                      addNotification({
                        message: "Network error",
                        severity: "error",
                      })
                    );
                  },
                });
              }
              break;
          }
        }
        break;
      case AUTH_BY_POS_PHYSICAL_CARD_REQUEST:
        {
          axiosPost({
            method: "GET",
            url:
              process.env.REACT_APP_SERVER_URL +
              "/user/byTagNumber/" +
              form.cardNumber,
            data: {},
            setLoadingToFalse: false,
            onThen: (response) => {
              if (response.status === 200) {
                const customer = response.data[0];

                if (
                  customer.rewards.findIndex(
                    (r) => r.name === "Basic" && ![1].includes(r.loyaltyLevel)
                  ) !== -1
                ) {
                  //card is already assigned
                  dispatch(
                    setFormErrors({
                      cardNumber:
                        "Takové číslo karty nemůže být použito, protože karta je již přidělena",
                    })
                  );
                  dispatch(setAuthWizardLoading(false));
                }

                if (
                  customer.rewards.findIndex(
                    (r) => r.name === "Basic" && [1].includes(r.loyaltyLevel)
                  ) !== -1
                ) {
                  //card is not assigned yet

                  if (!checkedUser.exists) {
                    const data = buildProfile({
                      login: form.login,
                      options: buildProfileOptions({ profileForm: null }),
                      rewards: buildProfileRewards(),
                      attributes: buildProfileAttributes({
                        RegistredAtStore: true,
                      }),
                    });
                    data.userNumber = customer.userNumber;
                    addAttributeFullyRegistred(data, "False");

                    axiosPost({
                      url: process.env.REACT_APP_SERVER_URL + "/user/upsert",
                      data: data,
                      onThen: (response2) => {
                        dispatch(
                          setAuthWizardStep(AUTH_BY_POS_PHYSICAL_CARD_REQUESTED)
                        );
                      },
                      onCatch: (error2) => {
                        dispatch(
                          addNotification({
                            message: "Network error",
                            severity: "error",
                          })
                        );
                      },
                      onFinally: () => {
                        dispatch(setAuthWizardLoading(false));
                      },
                    });
                  }

                  if (checkedUser.exists && checkedUser.userNumber !== null) {
                    const data = {
                      number: form.cardNumber,
                    };
                    axiosPost({
                      url:
                        process.env.REACT_APP_SERVER_URL +
                        "/user/addTag/" +
                        checkedUser.userNumber,
                      data: data,
                      onThen: (response2) => {
                        dispatch(
                          setAuthWizardStep(AUTH_BY_POS_PHYSICAL_CARD_REQUESTED)
                        );
                      },
                      onCatch: (error2) => {
                        dispatch(
                          addNotification({
                            message: "Network error",
                            severity: "error",
                          })
                        );
                      },
                      onFinally: () => {
                        dispatch(setAuthWizardLoading(false));
                      },
                    });
                  }
                }
              } else {
                dispatch(
                  addNotification({
                    message: "Network error: HTTP Response code is not 200",
                    severity: "error",
                  })
                );
                dispatch(setAuthWizardLoading(false));
              }
            },
            onCatch: (error) => {
              //console.log(error);
              if (error.response.status === 404) {
                //card not found
                dispatch(
                  setFormErrors({
                    cardNumber:
                      "Takové číslo karty neexistuje, nebo nemůže být použito.",
                  })
                );
                dispatch(setAuthWizardLoading(false));
              } else {
                dispatch(
                  addNotification({
                    message:
                      "Network error: HTTP Response code is not 200 or 404",
                    severity: "error",
                  })
                );
                dispatch(setAuthWizardLoading(false));
              }
            },
          });
        }
        break;
    }
  }
};

export const handleKeyDown = (event, params) => {
  if (event.key === "Enter") {
    handleSubmitAuthWizardForm(params);
  }
};
