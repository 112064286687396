import { createTheme } from "@mui/material";
import { brown, orange, pink, red } from "@mui/material/colors";
import {
  validationRuleFnIsNotBlank,
  validationRuleFnIsTrue,
} from "../utils/validation";
import { getBrandText } from "./brandText";

const globalThemeTypography = {
  allVariants: {
    fontFamily: "inherit",
    fontSize: "inherit",
  },
};

const globalThemeComponents = {
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        fontSize: "inherit",
        fontWeight: "normal",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root": {
          background: "#fff",
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        width: 42,
        height: 42,
        "& svg": {
          width: 24,
          height: 24,
        },
        "& .MuiTouchRipple-root": {
          width: 42,
          height: 42,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: 14,
        whiteSpace: "nowrap",
      },
      head: {
        fontWeight: 600,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontWeight: 600,
      },
    },
  },
};

const globalThemeStandalone = {
  palette: {
    primary: {
      main: "#c30064",
    },
    background: {
      default: "#f8fafc",
    },
    text: {
      primary: "#003264",
    },
  },
  typography: {
    // allVariants: {
    //   fontFamily: "inherit",
    //   fontSize: "inherit",
    // },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 400,
          fontSize: 40,
        },
        h2: {
          fontWeight: 400,
          fontSize: 30,
        },
        h3: {
          fontWeight: 400,
          fontSize: 20,
        },
      },
    },
  },
};

export const BRANDS = {
  relay: {
    getBrandText: (code, defaultValue = "") => {
      const text = getBrandText("relay", code);
      if (!text) {
        return defaultValue;
      }
      return text;
    },
    profileForm: {
      validationSchema: {
        checkVOP: [
          {
            ruleFn: validationRuleFnIsTrue,
            message: "Souhlas s obchodními podmínkami je povinný",
          },
        ],
      },
    },
    title: "Relay Club",
    faviconHref: "https://www.relay.cz/favicon-32x32.png",
    vopHref:
      "https://www.relay.cz/src/uploads/obchodni-podminky-pro-vernostni-klub.pdf",
    gdprHref:
      "https://www.relay.cz/src/uploads/souhlas-se-zpracovanim-osobnich-udaju-pro-vernostni-klub.pdf",
    theme: createTheme({
      typography: {
        ...globalThemeTypography,
      },
      palette: {
        primary: {
          main: "#ca0538",
        },
      },
      components: {
        ...globalThemeComponents,
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: "#f2f2f2",
              borderRadius: 0,
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              textAlign: "left",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              backgroundColor: "#fff",
              borderRadius: "8px !important",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: "inherit",
              boxShadow: "none",
              borderRadius: "8px !important",
              textTransform: "unset",
              "&:hover": {
                boxShadow: "none",
              },
            },
            contained: {
              fontWeight: 600,
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h1: {
              fontFamily: "'Caveat Brush', cursive",
              textAlign: "center",
              fontSize: 50,
            },
            h2: {
              fontWeight: "600 !important",
              color: "#ca0538",
              fontSize: 24,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              width: 42,
              height: 42,
              "& svg": {
                width: 24,
                height: 24,
              },
              "& .MuiTouchRipple-root": {
                width: 42,
                height: 42,
              },
            },
          },
        },
        MuiMenuItem: {
          variants: [
            {
              props: { variant: "menu" },
              style: {
                fontSize: 15,
                fontWeight: 600,
                borderRadius: 8,
                "&.Mui-selected": {
                  background: "#ca0538",
                  color: "#fff",
                  "& svg": {
                    fill: "#fff",
                  },
                  "&:hover": {
                    color: "inherit",
                    "& svg": {
                      fill: "inherit",
                    },
                  },
                },
              },
            },
          ],
        },
      },
    }),
    themeStandalone: createTheme({
      ...globalThemeStandalone,
    }),
    texts: {
      brand: "Relay",
    },
  },
  airclub: {
    getBrandText: (code, defaultValue = "") => {
      const text = getBrandText("airclub", code);
      if (!text) {
        return defaultValue;
      }
      return text;
    },
    profileForm: {
      addedFields: {
        companyTitle: {
          name: "companyTitle",
          label: "Název společnosti",
          type: "singleChoice",
          required: true,
          defaultValue: "Lagardere Travel Retail",
          options: [
            {
              value: "Lagardere Travel Retail",
              label: "Lagardere Travel Retail",
            },
            { value: "Lagardere Duty Free", label: "Lagardere Duty Free" },
            { value: "Letiště Praha", label: "Letiště Praha" },
            {
              value: "Czech Airlines Handling",
              label: "Czech Airlines Handling",
            },
            {
              value: "Czech Airlines Technics",
              label: "Czech Airlines Technics",
            },
          ],
        },
        employeeCardNumber: {
          name: "employeeCardNumber",
          label: "Číslo zaměstnanecké karty",
          type: "text",
          required: true,
          defaultValue: "",
          helperText: 'Vyplňte prosím číslo, které následuje po "PRG No:"',
        },
      },
      validationSchema: {
        companyTitle: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Vyberte prosím svoji společnost",
          },
        ],
        employeeCardNumber: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím číslo své zaměstnanecké karty",
          },
        ],
        checkVOP: [
          {
            ruleFn: validationRuleFnIsTrue,
            message: "Souhlas s obchodními podmínkami je povinný",
          },
        ],
        firstName: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím křestní jméno",
          },
        ],
        lastName: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím příjmení",
          },
        ],
      },
    },
    title: "Airclub Club",
    faviconHref: "https://www.relay.cz/favicon-32x32.png",
    vopHref: "https://airclub.qdv.cz/obchodni-podminky-pro-vernostni-klub.pdf",
    gdprHref:
      "https://airclub.qdv.cz/souhlas-se-zpracovanim-osobnich-udaju-pro-vernostni-klub.pdf",
    theme: createTheme({
      typography: {
        ...globalThemeTypography,
      },
      palette: {
        primary: {
          main: "#24295a",
        },
      },
      components: {
        ...globalThemeComponents,
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: "#fff",
              // borderRadius: 0
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              textAlign: "left",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              backgroundColor: "#fff",
              borderRadius: "8px !important",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: "inherit",
              boxShadow: "none",
              color: "#fff",
              borderRadius: "8px !important",
              textTransform: "unset",
              "&:hover": {
                boxShadow: "none",
              },
            },
            contained: {
              fontWeight: 600,
            },
            outlined: {
              color: "#24295a",
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h1: {
              // fontFamily: "'Caveat Brush', cursive",
              textAlign: "center",
              fontSize: 50,
            },
            h2: {
              // fontWeight: "600 !important",
              color: "#24295a",
              fontSize: 24,
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              width: 42,
              height: 42,
              "& svg": {
                width: 24,
                height: 24,
              },
              "& .MuiTouchRipple-root": {
                width: 42,
                height: 42,
              },
            },
          },
        },
        MuiMenuItem: {
          variants: [
            {
              props: { variant: "menu" },
              style: {
                fontSize: 15,
                fontWeight: 600,
                borderRadius: 8,
                "&.Mui-selected": {
                  background: "#969fc4",
                  color: "#fff",
                  "& svg": {
                    fill: "#fff",
                  },
                  "&:hover": {
                    color: "inherit",
                    "& svg": {
                      fill: "inherit",
                    },
                  },
                },
              },
            },
          ],
        },
      },
    }),
    themeStandalone: createTheme({
      ...globalThemeStandalone,
    }),
    texts: {
      brand: "Airclub",
    },
  },
  paul: {
    profileForm: {
      validationSchema: {
        checkVOP: [
          {
            ruleFn: validationRuleFnIsTrue,
            message: "Souhlas s obchodními podmínkami je povinný",
          },
        ],
        firstName: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím křestní jméno",
          },
        ],
        lastName: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím příjmení",
          },
        ],
      },
    },
    title: "Věrnostní program PAUL",
    faviconHref: "https://www.paul-cz.com/favicon-32x32.png",
    theme: createTheme({
      palette: {
        primary: orange,
      },
    }),
    texts: {
      brand: "PAUL",
    },
  },
  hello: {
    profileForm: {
      validationSchema: {
        checkVOP: [
          {
            ruleFn: validationRuleFnIsTrue,
            message: "Souhlas s obchodními podmínkami je povinný",
          },
        ],
      },
    },
    title: "Věrnostní klub Hello!",
    faviconHref: "https://www.pekarstvi-hello.cz/favicon-32x32.png",
    theme: createTheme({
      palette: {
        primary: {
          main: "#ff9e16",
        },
      },
      components: {
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: "#f2f2f2",
              borderRadius: 0,
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h1: {
              fontFamily: "'SignPainter', cursive",
              textAlign: "center",
              fontSize: 50,
            },
            h2: {
              fontWeight: "600 !important",
              color: "#ff9e16",
              fontSize: 24,
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              backgroundColor: "#fff",
              borderRadius: "0 !important",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: "inherit",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            },
            contained: {
              fontWeight: 600,
              color: "#fff",
            },
          },
        },
      },
    }),
    themeStandalone: createTheme({
      ...globalThemeStandalone,
    }),
    texts: {
      brand: "Hello!",
    },
  },
  costa: {
    profileForm: {
      validationSchema: {
        checkVOP: [
          {
            ruleFn: validationRuleFnIsTrue,
            message: "Souhlas s obchodními podmínkami je povinný",
          },
        ],
      },
    },
    title: "Věrnostní program Costa Coffee",
    faviconHref: "https://www.costa-coffee.cz/templates/costa/favicon.ico",
    theme: createTheme({
      palette: {
        primary: pink,
      },
    }),
    texts: {
      brand: "Costa Coffee",
    },
  },
};
