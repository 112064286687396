import {
  AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM,
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM,
  AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM,
  AUTH_BY_EMAIL_STEP_FORM,
  AUTH_BY_EMAIL_STEP_LOGIN_FORM,
  AUTH_BY_EMAIL_STEP_SIGNIN_FORM,
  AUTH_BY_POS_PHYSICAL_CARD_REQUEST,
  AUTH_BY_POS_START,
} from "./constants";
import {
  validationRuleFnIsEmail,
  validationRuleFnIsInLength,
  validationRuleFnIsNotBlank,
  validationRuleFnIsSameAs,
} from "../../utils/validation";

export const getValidationSchema = (step, form) => {
  switch (step) {
    case AUTH_BY_EMAIL_STEP_FORM: {
      return {
        login: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím e-mail",
          },
          { ruleFn: validationRuleFnIsEmail, message: "Zadejte prosím e-mail" },
        ],
      };
    }
    case AUTH_BY_EMAIL_STEP_LOGIN_FORM: {
      return {
        login: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím e-mail",
          },
          { ruleFn: validationRuleFnIsEmail, message: "Zadejte prosím e-mail" },
        ],
        password: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím heslo",
          },
        ],
      };
    }
    case AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM: {
      return {
        login: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím e-mail",
          },
          { ruleFn: validationRuleFnIsEmail, message: "Zadejte prosím e-mail" },
        ],
        password: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím heslo",
          },
          {
            ruleFn: validationRuleFnIsInLength(8, 999),
            message: "Minimální délka hesla je 8 znaků",
          },
        ],
        password2: [
          {
            ruleFn: validationRuleFnIsSameAs(form.password),
            message: "Hesla nejsou stejná",
          },
        ],
      };
    }
    case AUTH_BY_EMAIL_STEP_SIGNIN_FORM: {
      return {
        login: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím e-mail",
          },
          { ruleFn: validationRuleFnIsEmail, message: "Zadejte prosím e-mail" },
        ],
      };
    }
    case AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_REQUEST_FORM: {
      return {
        login: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím e-mail",
          },
          { ruleFn: validationRuleFnIsEmail, message: "Zadejte prosím e-mail" },
        ],
      };
    }
    case AUTH_BY_EMAIL_STEP_FORGOTTEN_PASSWORD_RESET_FORM: {
      return {
        password: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím heslo",
          },
          {
            ruleFn: validationRuleFnIsInLength(8, 999),
            message: "Minimální délka hesla je 8 znaků",
          },
        ],
        password2: [
          {
            ruleFn: validationRuleFnIsSameAs(form.password),
            message: "Hesla nejsou stejná",
          },
        ],
      };
    }
    case AUTH_BY_POS_START: {
      return {
        login: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím e-mail zákazníka",
          },
          {
            ruleFn: validationRuleFnIsEmail,
            message: "Zadejte prosím e-mail zákazníka",
          },
        ],
      };
    }
    case AUTH_BY_POS_PHYSICAL_CARD_REQUEST: {
      return {
        cardNumber: [
          {
            ruleFn: validationRuleFnIsNotBlank,
            message: "Zadejte prosím číslo karty",
          },
        ],
      };
    }
    default: {
      return {};
    }
  }
};

export const getValidationSchemaForBrand = (step, brand) => {
  switch (step) {
    case AUTH_BY_EMAIL_STEP_COMPLETE_PROFILE_FORM: {
      return brand.profileForm.validationSchema;
    }
    default: {
      return {};
    }
  }
};
