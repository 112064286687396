import React from "react";
import { useSelector } from "react-redux";
import { selectProfile } from "../../../selectors/appSelectors";
import { callApiForProfilePage } from "../utils";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatDate } from "../../../utils/datetime";
import { formatPrice, formatPriceUnit } from "../../../utils/price";
import ProfileLoading from "../../ProfileLoading/ProfileLoading";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";

const airmeCode2Text = {
  10: "Body získané prostřednictvím kampaně",
  11: "Navrácení bodů zpět ze zrušeného nákupu",
  12: "Čerpání bodů ze zrušeného nákupu",
  9: "Expirace bodů",
  13: "Vrácené body za nepoužitý poukaz",
  14: "Získané body za nákup", //e-shop
  5: "Získané body za nákup", //in-store
  0: "Ruční změna bodů",
  6: "Čerpání bodů", //e-shop
  8: "Čerpání bodů", //in-store
  7: "Vrácené body z pokladny",
  15: "Použití bodů na poukaz",
};

const sortFn = (a, b) => {
  return new Date(b.created).getTime() - new Date(a.created).getTime();
};

const MyAccount = () => {
  const profile = useSelector(selectProfile);

  const [loading, setLoading] = React.useState(false);
  const [totalPoints, setTotalPoints] = React.useState(0);
  const [points, setPoints] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] = React.useState(null);

  const openTransaction = (uid) => {
    setOpen(true);
    setSelectedTransaction(null);
    callApiForProfilePage({
      url: "/airme-proxy/PATransactions/GetStatTransaction/" + uid,
      setLoading: setOpenLoading,
      onSuccess: (r) => {
        if (r.status === 200) {
          setSelectedTransaction(r.data[0]);
        }
      },
      onFinally: () => {
        setOpenLoading(false);
      },
    });
  };

  const closeTransaction = () => {
    setOpen(false);
    setTimeout(() => {
      setSelectedTransaction(null);
    }, 500);
  };

  const totalPrice = (transaction) => {
    return transaction.items.reduce((prev, current) => {
      return prev + current.totalPrice * 1;
    }, 0);
  };

  const load = (profile) => {
    callApiForProfilePage({
      url:
        "/airme-proxy/PAUsers/getLoyaltyPointsHistory/" +
        profile.userNumber +
        "/Basic",
      setLoading,
      onSuccess: (r) => {
        if (r.status === 200) {
          setTotalPoints(r.data[0].value);
          setPoints([...r.data[0].history].sort(sortFn));
        }
      },
    });
  };

  const formatDescription = (value) => {
    if (value === 0) {
      return "Nákup bez zisku bodů";
    }
    if (value > 0) {
      return "Nákup se ziskem bodů";
    }
    if (value < 0) {
      return "Nákup s čerpáním bodů";
    }
  };

  const formatPoints = (value) => {
    if (value === 0) {
      return "";
    }
    const p = formatPrice(value);

    return Number.parseFloat(p).toFixed(2);
  };

  React.useEffect(() => {
    if (!!profile) {
      load(profile);
    }
  }, [profile]);

  const pl = [2, 3, 4].includes(totalPoints)
    ? "body"
    : [1].includes(totalPoints)
    ? "bod"
    : "bodů";

  if (loading) {
    return <ProfileLoading />;
  }

  return (
    <>
      <Dialog open={open} onClose={closeTransaction} fullWidth maxWidth="sm">
        <DialogTitle>Položky nákupu</DialogTitle>
        <Divider />
        <DialogContent>
          {openLoading && <ProfileLoading />}
          {!!selectedTransaction && (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Množství</TableCell>
                    <TableCell>Název</TableCell>
                    <TableCell>Cena</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTransaction.items.map((t, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>{t.quantity}&nbsp;x</TableCell>
                        <TableCell>{t.productText}</TableCell>
                        <TableCell>{formatPriceUnit(t.totalPrice)}</TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={2}>Celkem</TableCell>
                    <TableCell>
                      <strong>
                        {formatPriceUnit(totalPrice(selectedTransaction))}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={closeTransaction}
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<CloseIcon />}
          >
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="body1">
        Aktuální stav <strong>{formatPoints(totalPoints)}</strong> {pl}!
      </Typography>
      {/*<Typography variant={"body2"} mt={2} mb={1}>*/}
      {/*    <strong>Historie bodů:</strong>*/}
      {/*</Typography>*/}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Popis</TableCell>
              <TableCell>Body</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {points.map((point, index) => {
              const hiddenBtn = point.statTransactionUID.startsWith("0000");
              return (
                <TableRow hover key={index}>
                  <TableCell>{formatDate(point.created)}</TableCell>
                  <TableCell>{formatDescription(point.value)}</TableCell>
                  <TableCell>{formatPoints(point.value)}</TableCell>
                  <TableCell
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {!hiddenBtn && (
                      <Button
                        onClick={() => {
                          openTransaction(point.statTransactionUID);
                        }}
                        color="primary"
                        variant="outlined"
                        size="small"
                        startIcon={<OpenInNewIcon />}
                      >
                        Zobrazit položky
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyAccount;
